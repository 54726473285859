import React, { useEffect, useState } from "react";
import "./StudentAnimatedBackground.css";
import Ellipsis from "../Ellipsis/Ellipsis";

function StudentAnimatedBackground({ nearestClass }) {
  const [status, setStatus] = useState("Yet to Start");

  useEffect(() => {
    if (nearestClass) {
      const checkStatus = () => {
        const now = new Date();
        const startDate =
          now.getFullYear() +
          "-" +
          String(now.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(now.getDate()).padStart(2, "0");
        const startTime = new Date(`${startDate}T${nearestClass.start_time}`);
        console.log(startTime);
        // Update status based on whether the class has started
        if (now >= startTime) {
          setStatus("Started");
        } else {
          setStatus("Yet to Start");
        }
      };

      checkStatus();

      // Update status every minute
      const intervalId = setInterval(checkStatus, 60000);

      // Cleanup interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [nearestClass]);

  return (
    <div className="animated-background-section">
      <div className="context">
        {nearestClass && Object.keys(nearestClass).length !== 0 ? (
          <div className="upcoming-lecture-section">
            <div className="upcoming-lecture-title">
              <p>
                <Ellipsis text={nearestClass?.course} maxLength="50" />
              </p>
              <h5 style={{ color: "orange",marginLeft:"2em" }}>
                {new Date(
                  `${nearestClass.start_date}T${nearestClass.start_time}`
                ).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </h5>
            </div>
            <div className="join-now-meeting-btn">
              <button
                onClick={() => window.open(nearestClass.join_url, "_blank")}
                disabled={status === "Yet to Start"}
              >
                <div className="original">
                  {status === "Yet to Start" ? "Upcoming" : "Join Now"}
                </div>
                <div className="letters">
                  {status === "Yet to Start" ? (
                    <>
                      <span>U</span>
                      <span>P</span>
                      <span>C</span>
                      <span>O</span>
                      <span>M</span>
                      <span>I</span>
                      <span>N</span>
                      <span>G</span>
                    </>
                  ) : (
                    <>
                      <span>J</span>
                      <span>O</span>
                      <span>I</span>
                      <span>N</span>
                      <span>&nbsp;</span>
                      <span>N</span>
                      <span>O</span>
                      <span>W</span>
                    </>
                  )}
                </div>
              </button>
            </div>
          </div>
        ) : (
          <p>No upcoming lecture</p>
        )}
      </div>
      <div className="area">
        <ul className="circles">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>
      </div>
    </div>
  );
}

export default StudentAnimatedBackground;
