import React from "react";
import "./AssignmentMain.css";
import AssignmentCard from "../AssignmentCard/AssignmentCard";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getStudentAssignments } from "./apiAssignment";

function AssignmentMain() {
  const queryClient = useQueryClient();
  const studentId = queryClient.getQueryData(["user"])?.user?._id;
  const { isLoading, data: assignements } = useQuery({
    queryKey: ["assignements"],
    queryFn: () => getStudentAssignments({ studentId }),
  });
  return (
    <div className="assignmentmain-section">
      <div className="assignmentmain-container">
        <div className="heading-bell-container">
          <p className="student-welcome-heading">Assignments</p>
          <div className="profile-notification-container">
            {/* <p className="bell-icon material-symbols-outlined">notifications</p> */}
            <ProfileIcon />
          </div>
        </div>
        {assignements?.assignments?.map((assig, i) => (
          <div className="assignment-main-cards-container" key={i}>
            <AssignmentCard assignment={assig} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AssignmentMain;
