import React, { useEffect, useState } from 'react'
import StudentAssessmentCard from '../../components/StudentAssessment/StudentAssessmentCard'
import { SideBar } from '../../components/SideBar/SideBar'
import { getCurrentUser } from '../../services/restApiAuth';

const StudentAssessmentPage = () => {
    const [courseName, setCourseName] = useState("");
    useEffect(() => {
        const fetchUser = async () => {
          try {
            const userData = await getCurrentUser();
            let bookedSchedules = userData?.user?.classes?.filter((s) => s.is_booked);
            console.log(bookedSchedules, "bookedSchedules1");
            setCourseName(bookedSchedules[0].course);
            
        } catch (error) {
            console.error("Error fetching user data:", error);
          }
        };
    
        fetchUser();
      }, []);
      const firstCourseString = courseName || '';
      console.log("course name:", firstCourseString)
      const assignedCourseCode = firstCourseString.split(':')[0].trim();
      
      
    
      return (
        <div style={{display:'flex'}} className='assessment-container'>
          <SideBar />
          <StudentAssessmentCard studentCourseTitle={assignedCourseCode} />
        </div>
      );
};


export default StudentAssessmentPage
