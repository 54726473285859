import React, { useState } from 'react';
import VideoCard from './VideoCard';
import VideoPlayer from './VideoPlayer';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { Slide, Typography } from '@mui/material';
import MaterialCard from './MaterialCard';
import Grid2 from '@mui/material/Unstable_Grid2';

const MaterialLinkCard = () => {
  const materials = [
    {
      title: 'Mastering Daily Sentences',
      description: 'Ultimate guide to mastering daily sentences.',
      thumbnail: 'https://images.pexels.com/photos/6503100/pexels-photo-6503100.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg',
      url: 'https://www.canva.com/design/DAGcJne8qos/HvhiKkyVDxoo5fYu4ES3ZQ/view?utm_content=DAGcJne8qos&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hf2c7caea21#1'
    },
    {
      title: 'Mastering Communication',
      description: 'From the basics to advanced levels.',
      thumbnail: 'https://images.pexels.com/photos/5792852/pexels-photo-5792852.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1.jpg',
      url: 'https://www.canva.com/design/DAGcESJpbgc/cDQsJInb7Ub2FguYB4ODbw/view?utm_content=DAGcESJpbgc&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=hbb52588c60#1'
    },
    {
      title: 'Grammar for Communication',
      description: 'Mastering english grammar for effective communication.',
      thumbnail: 'https://images.pexels.com/photos/5896470/pexels-photo-5896470.jpeg',
      url: 'https://www.canva.com/design/DAGc1DWujtM/UtWCX3AnCAs690fnRy-jaQ/view?utm_content=DAGc1DWujtM&utm_campaign=designshare&utm_medium=link2&utm_source=uniquelinks&utlId=h7cc71adce1'
    },
    // Add more video objects as needed
  ];

  return (
    <Container sx={{ pt: 0, pb: 4 }}>
        <>
            <div className="heading-bell-container">
              <p className="student-welcome-heading">Study Materials</p>
              <div className="profile-notification-container">
                {/* <p className="bell-icon material-symbols-outlined">notifications</p> */}
                <p className="profile-icon material-symbols-outlined">
                  account_circle
                </p>
              </div>
            </div>
          <Grid2 container spacing={4}>
            { materials.map((material, index) =>(
              <Grid2 item key={material.title} xs={12} sm={6} md={4}>
                    <MaterialCard material={material}/>
              </Grid2>
            ))}
          </Grid2>
        </>
    </Container>
  );

};

export default MaterialLinkCard;
