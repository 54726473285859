import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react'
import './App.css';
import HomePage from './pages/HomePage/Homepage';
import StudentDashboard from './pages/StudentDashboard/StudentDashboard';
import LoginSigninForm from './components/LoginSigninForm/LoginSigninForm';
import ProtectedRoute from './authentication/protectedRoute';
import StudyMaterial from '../src/pages/StudyMaterial/StudyMaterial'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AppLayout from './components/AppLayout';
import AssignmentPage from './pages/AssignmentPage/AssignmentPage';
import DiscussionPage from './pages/DiscussionPage/DiscussionPage';
import QuestionDetail from './components/QuestionDetail/QuestionDetail';
import questionsData from './components/questionsData';
import AssignmentInnerDetails from './pages/AssignmentInnerDetails/AssignmentInnerDetails';
import ProfileSectionPage from './pages/ProfileSectionPage/ProfileSectionPage';
import StudentAssessmentPage from './pages/StudentAssessmentPage/StudentAssessmentPage';


const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0
    }
  }
})


function App() {

  return (
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<ProtectedRoute>
              <AppLayout />
            </ProtectedRoute>}>
              <Route exact path='/studenthome' element={<StudentDashboard />} />
              <Route exact path='/studymaterial' element={<StudyMaterial />} />
              <Route exact path='/assignment' element={<AssignmentPage />} />
              <Route exact path='/assignmentdetails' element={<AssignmentInnerDetails />} />
              <Route exact path='/discussion' element={<DiscussionPage />} />
              <Route exact path='/profile' element={<ProfileSectionPage />} />
              <Route path="/questions/:questionId" element={<QuestionDetail questions={questionsData} />} />
              <Route exact path='/studentassessment' element={<StudentAssessmentPage />} />
            </Route>
            <Route exact path='/' element={<LoginSigninForm />} />
          </Routes>
        </Router>
      </QueryClientProvider>
  );
}

export default App;
