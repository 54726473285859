// StudentAssessmentCard.jsx
import React from 'react';
import { useSpring, animated } from 'react-spring';
import './StudentAssessmentCard.css';

// Static data for courses and assessments
const staticCourses = [
  {
    id: "A1",
    title: "A1",
    assessments: [
      {
        id: "A1_Test1",
        title: "Test 1 (Units 1-4)",
        link: "https://accentaura.com/courses/A1/A1_Test1",
      },
      {
        id: "A1_Test2",
        title: "Test 2 (Units 5-8)",
        link: "https://accentaura.com/courses/A1/A1_Test2",
      },
      {
        id: "A1_Test3",
        title: "Test 3 (Units 9-12)",
        link: "https://accentaura.com/courses/A1/A1_Test3",
      },
      {
        id: "A1_Test4",
        title: "Test 4 (Units 13-18)",
        link: "https://accentaura.com/courses/A1/A1_Test4",
      },
    ],
  },
  {
    id: "A2",
    title: "A2",
    assessments: [
      {
        id: "A2_Test1",
        title: "Test 1 (Units 1-4)",
        link: "https://accentaura.com/courses/A2/A2_Test1",
      },
      {
        id: "A2_Test2",
        title: "Test 2 (Units 5-8)",
        link: "https://accentaura.com/courses/A2/A2_Test2",
      },
      {
        id: "A2_Test3",
        title: "Test 3 (Units 9-12)",
        link: "https://accentaura.com/courses/A2/A2_Test3",
      },
      {
        id: "A2_Test4",
        title: "Test 4 (Units 13-18)",
        link: "https://accentaura.com/courses/A2/A2_Test4",
      },
    ],
  },
  {
    id: "B1",
    title: "B1",
    assessments: [
      {
        id: "B1_Test1",
        title: "Test 1 (Units 1-4)",
        link: "https://accentaura.com/courses/B1/B1_Test1",
      },
      {
        id: "B1_Test2",
        title: "Test 2 (Units 5-8)",
        link: "https://accentaura.com/courses/B1/B1_Test2",
      },
      {
        id: "B1_Test3",
        title: "Test 3 (Units 9-12)",
        link: "https://accentaura.com/courses/B1/B1_Test3",
      },
      {
        id: "B1_Test4",
        title: "Test 4 (Units 13-18)",
        link: "https://accentaura.com/courses/B1/B1_Test4",
      },
    ],
  },
  {
    id: "B2",
    title: "B2",
    assessments: [
      {
        id: "B2_Test1",
        title: "Test 1 (Units 1-4)",
        link: "https://accentaura.com/courses/B2/B2_Test1",
      },
      {
        id: "B2_Test2",
        title: "Test 2 (Units 5-8)",
        link: "https://accentaura.com/courses/B2/B2_Test2",
      },
      {
        id: "B2_Test3",
        title: "Test 3 (Units 9-12)",
        link: "https://accentaura.com/courses/B2/B2_Test3",
      },
      {
        id: "B2_Test4",
        title: "Test 4 (Units 13-18)",
        link: "https://accentaura.com/courses/B2/B2_Test4",
      },
    ],
  },
];

const StudentAssessmentCard = ({ studentCourseTitle }) => {
  // Find the course in static data that matches the student's assigned course title.
  const course = staticCourses.find((c) => c.id === studentCourseTitle);

  if (!course) {
    return <p>No assessments available for your course.</p>;
  }

  return (
    <div className="assessment-card">
      <h2>{course.title} Assessments</h2>
      <div className="assessments-container">
        {course.assessments.map((assessment) => (
          <AssessmentItem key={assessment.id} assessment={assessment} />
        ))}
      </div>
    </div>
  );
};

const AssessmentItem = ({ assessment }) => {
  const [hover, setHover] = React.useState(false);
  const animatedStyle = useSpring({
    transform: hover ? 'scale(1.02)' : 'scale(1)',
    boxShadow: hover
      ? '0px 8px 15px rgba(0, 0, 0, 0.3)'
      : '0px 4px 10px rgba(0, 0, 0, 0.1)',
    config: { tension: 300, friction: 10 },
  });

  return (
    <animated.div
      className="assessment-item-card"
      style={animatedStyle}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <h3 className="assessment-title">{assessment.title}</h3>
      <animated.a
        href={assessment.link}
        target="_blank"
        rel="noopener noreferrer"
        className="take-assessment-button"
      >
        Take Assessment
      </animated.a>
    </animated.div>
  );
};

export default StudentAssessmentCard;
